<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                      @change="perPageChange"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-">
              <b-table
                :items="bookingData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="dataLoading"
                @row-clicked="selectedUser"
                :hover="true"
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="pageChange"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { getBookingList } from "@/services/api/booking";

export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      bookingData: [],
      dataLoading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      fields: [
        { key: "booking_date" },
        { key: "price" },
        { key: "tribe_id.name", label: "Tribe Name" },
        { key: "program_id.name", label: "Program Name" },
        { key: "user_id.fullname", label: "User Name" },
      ],
    };
  },
  computed: {
    rows() {
      return this.bookingData.length;
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  created() {
    this.getAllBookingList();
  },
  methods: {
    getAllBookingList() {
      this.bookingData = [];
      this.dataLoading = true;
      getBookingList({ page: 1, perPage: 10 })
        .then((res) => {
          console.log(res.data);
          this.bookingData = res.data.bookings;
          console.log("START");
          console.log(this.bookingData);
          console.log("END");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log("Loaded");
        });
    },
  },
};
</script>
<style>
</style>