import { DataService } from "@/utils/dataService";


async function getBookingList(params){
  return await DataService.get('/admin/booking/getAll',params)
}

export{
  getBookingList

}
